<template lang="pug">
	main.layout
		template(v-if="isResetCache")
			RouterView
		template(v-else)
			Header
			global-filter(
				@update-filters="updateFilters"
				@filter-status="filterStatus"
			)
			.layout__content
				RouterView(
					v-bind="params"
					:need-update-result="isNeedUpdate"
					:is-doubled-period="isDoubledPeriod"
					:date="date"
					:is-filter-full="isFilterFull"
				)
			NestleToggle
</template>

<script>
import { mapActions } from "vuex"
import NestleToggle from '@/components/Elements/NestleToggle.vue'
import Header from '@/components/Nestle/Layout/Header.vue'
import GlobalFilter from '@/components/Nestle/GlobalFilter/GlobalFilter.vue'

export default {
	components: {
		Header,
		NestleToggle,
		GlobalFilter,
	},

	data() {
		return {
			isNeedUpdate: false,
			isDoubledPeriod: false,
			date: {
				dateFrom: new Date(new Date().getFullYear(), new Date().getMonth()-1, 0),
				dateTo: new Date(),
			},
			isFilterFull: false,
			params: null,
		}
	},

	computed: {
		// TODO после сброса кэша не появляется дата в LS и сама дата
		isResetCache() {
			return this.$route.name === 'Nestle.ResetCache';
		},

	},

	methods: {
		...mapActions('app', ['getCategories']),

		updateFilters(value, isDoubledPeriod = false) {
			this.params = value
			this.isNeedUpdate = !this.isNeedUpdate;

			if (isDoubledPeriod) {
				this.isDoubledPeriod = true;
			} else {
				this.isDoubledPeriod = false;
			}
		},

		filterStatus(value) {
			this.isFilterFull = value;
		},
	},

}
</script>

<style lang="scss">
.layout {
	min-height: 100vh;
	background-color: color(gray-200);
	min-width: 1280px;
}
</style>
